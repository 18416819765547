import React from "react"
import { graphql } from "gatsby"
import cx from "classnames"

import Layout from "../components/layout/layout"
import Seo from "../components/seo/seo"
import Wrapper from "../components/layout/wrapper/wrapper"
import Team from "../components/team/team"
import HeroSimple from "../components/hero/heroSimple/heroSimple"
import BlockContent from "../components/blockContent/blockContent"
import Columns from "../components/columns/columns"
import PageBuilder from "../components/pageBuilder/pageBuilder"
import Cta from "../components/cta/cta"

import styles from "./about.module.scss"

const About = ({ data }) => {
  const { content, seo } = data.sanityAbout.tabs

  return (
    <Layout>
      <Seo isBlogPost={false} {...seo} />
      <HeroSimple blocks={content.hero[0].children} subtitle={content.subtitle} />
      {content.richText && (
        <Wrapper>
          <div className="grid">
            <div className={cx("gridItem small-12 medium-8 medium-offset-2", styles.center)}>
              <BlockContent blocks={content.richText} />
            </div>
          </div>
        </Wrapper>
      )}
      <PageBuilder pageBuilder={content.pageBuilder} />
      <Team team={content.team} />
      {content.richText2 ? (
        <Wrapper>
          <div className="grid">
            <div className="gridItem small-12 medium-8 medium-offset-2">
              <BlockContent blocks={content.richText2} />
            </div>
          </div>
        </Wrapper>
      ) : null}
      {content.columns?.columns.length ? <Columns columns={content.columns.columns} {...content.columns} /> : null}
      <Cta {...content.cta} background="blue" />
    </Layout>
  )
}

export default About

export const query = graphql`
  query {
    sanityAbout {
      tabs {
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
        content {
          subtitle
          hero: _rawHero
          richText: _rawRichText(resolveReferences: { maxDepth: 5 })
          richText2: _rawRichText2(resolveReferences: { maxDepth: 5 })
          columns {
            _key
            _type
            background
            alignment
            ...columnsFragment
          }
          pageBuilder {
            ...PageBuilder
          }
          team {
            about: _rawAbout(resolveReferences: { maxDepth: 5 })
            name
            role
            email
            linkedin
            image {
              _rawAsset
              asset {
                url
                fluid(maxWidth: 580) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          cta {
            ... on SanityCta {
              ...ctaFragment
            }
            ... on SanityCtaForm {
              ...ctaFormFragment
            }
          }
        }
      }
    }
  }
`
