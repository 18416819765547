import React from "react"
import cx from "classnames"

import BlockContent from "../blockContent/blockContent"
import Wrapper from "../layout/wrapper/wrapper"
import Image from "../image/image"
import * as Modal from "../modal/modal"
import SocialIcon from "../social/socialIcon"

import styles from "./team.module.scss"

const Team = ({ team }) => (
  <Wrapper>
    <h2 className={styles.title}>Meet the team</h2>
    <div className={cx("grid", styles.gap)}>
      {team.map(member => (
        <Modal.Wizard key={member.name}>
          <div className={cx("gridItem small-4 medium-2", styles.trigger)}>
            <Modal.Trigger>
              <div className={styles.triggerWrapper}>
                <Image image={member?.image?._rawAsset} alt={member.name} width="280" />
                <div className={styles.triggerHover}>
                  <p className={styles.name}>{member.name}</p>
                  <p>{member.role}</p>
                </div>
              </div>
            </Modal.Trigger>
          </div>
          <Modal.Content>
            <div className="grid">
              <div className="gridItem small-12 medium-offset-1 medium-4">
                {member?.image?.asset?.url ? (
                  <Image image={member?.image?._rawAsset} alt={member.name} width="600" styles={styles.image} />
                ) : null}
              </div>
              <div className="gridItem small-12 medium-5">
                <h1>{member.name}</h1>
                <p className={styles.role}>{member.role}</p>
                {member?.about ? <BlockContent blocks={member.about} /> : null}
                <div className={styles.social}>
                  <div className={styles.socialItem}>
                    {member?.email ? (
                      <a href={`mailto:${member?.email}`}>
                        <SocialIcon type="Mail" />
                        {member?.email}
                      </a>
                    ) : null}
                  </div>
                  <div className={styles.socialItem}>
                    {member?.linkedin ? (
                      <a href={member?.linkedin}>
                        <SocialIcon type="LinkedIn" />
                        Linkedin
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Content>
        </Modal.Wizard>
      ))}
    </div>
  </Wrapper>
)

export default Team
